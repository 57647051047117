import Component from 'vue-class-component';
import { loginHelper, mailService } from '@/main';
import PageRender from '../../models/PageRender';
import { AxiosResponse } from 'axios';
import to from 'await-to-js';
import EmailTemplate from '../../models/EmailTemplate';
import { VueEditor } from 'vue2-editor';
import { memberModule } from '@/store/modules/member';

@Component({
    components: {
        VueEditor,
    },
})
export default class HomeComponent extends PageRender {
    public emails: EmailTemplate[] = [];
    public isLoading: boolean = true;
    public scope: string = 'park';

    public get siteOwnerId() {
        return memberModule.member.siteOwnerIds[0];
    }

    public customControls: any = [
        [{ header: [false, 1, 2, 3] }],
        ['bold', 'italic', 'underline'],
        [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['link'],
        ['clean'],
    ];

    public async created() {
        this.scope = this.$route.meta.scope as string;
        this.emails = await this.getEmailTemplates();
        this.isLoading = false;
    }

    public async getEmailTemplates() {
        const [err, response] = await to<AxiosResponse<EmailTemplate[]>>(
            mailService.getEmailTemplates(this.scope === 'park' ? this.siteId : this.siteOwnerId, this.scope === 'park'),
        );
        if (err) {
            this.showError('Mislukt om de mailcontent op te halen');
            return;
        }
        return response.data;
    }

    public getFriendlyName(name) {
        return name
            .replaceAll('-', ' ')
            .replaceAll('_', '')
            .replace(this.siteId, '')
            .replace(this.siteOwnerId, '')
            .replace('siteowner', '');
    }

    public async saveContent(id: number) {
        const [err, response] = await to<AxiosResponse>(
            mailService.updateTemplate(
                id,
                this.emails.find((x) => x.Id === id),
            ),
        );
        if (err) {
            this.showError('Mislukt om de mailcontent te updaten.');
            return;
        }

        this.showSuccess('Mailcontent geupdatet.');
        return response.data;
    }
}
